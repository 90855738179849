import './scss/HeadingComponent.scss';
import { useSelector } from 'react-redux';
import { ReactComponent as ClubAutofactLogo } from '../../../resources/img/deafult-club-autofact-logo.svg';
import { StorageInterface } from '../../../store/StorageInterface';
import { ReactComponent as AutofactLogo } from '../../../resources/img/Logotipo-Autofact-Pro-SIN-ISOTIPO.svg';
import { ReactComponent as AutopressLogo } from '../../../resources/img/Logotipo-AutopressAZUL-SIN-TAGLNIE.svg';

function HeadingComponent() : JSX.Element {
  const headerSlice = useSelector(
    (state: StorageInterface) => state.headerSlice,
  );
  return (
    headerSlice?.isRetailHost === true
      ? (
        <div className="HeadingContainer retail-design">
          <div className="HeadingComponent m-auto animate__animated animate__fadeIn animate__faster">
            <div className="pb-0">
              <h1 className="mt-4 mt-md-0 text-black font-bold title">
                Te damos la bienvenida al
              </h1>
              <ClubAutofactLogo className="mb-5" />
              <h2 className="list">
                <div className="list-title">¿Por qué unirte al Club Autofact?</div>
                <ul className="li-class-check-pink">
                  <li><p>Descuentos exclusivos en Informes Autofact y Transferencias online</p></li>
                  <li><p>Revisa tu historial de compras en Autofact</p></li>
                  <li>
                    <p>
                      Recibe notificaciones relevantes para tus vehículos: permiso de circulación, revisión técnica y
                      otras.
                    </p>
                  </li>
                  <li><p>Canjea tus Informes previamente comprados y mucho más...</p></li>
                </ul>
              </h2>
            </div>
          </div>
        </div>
      )
      : (
        <div className="content-container HeadingContainer">
          <div className="HeadingComponent animate__animated animate__fadeIn animate__faster">
            <div className="container pb-0">
              <AutofactLogo className="logo-autofact" />
              <div className="separator" />
              <AutopressLogo className="logo-autopress" />
              <h4 className="mb-4 mt-4 title">
                ¡Te damos la bienvenida!
              </h4>
              <h4 className="content">
                AutofactPro y Autopress se unieron para ofrecer
                soluciones integrales a Concesionarios y Compraventas
                facilitando todos sus procesos: desde la retoma hasta la
                transferencia electrónica al cliente final.
              </h4>
            </div>
          </div>
        </div>
      )
  );
}

export default HeadingComponent;
