import './scss/LoadingComponent.scss';

export interface ILoadingComponent {
  text?: string | null;
  fullscreen?: boolean;
}

function LoadingComponent({ fullscreen, text }: ILoadingComponent) : JSX.Element {
  return (
    <div className={`${fullscreen ? 'loading-backdrop-fixed' : 'loading-backdrop'} d-flex justify-content-center align-self-center`}>
      <div>
        <div className="row text-center justify-content-center">
          <svg
            width="50"
            height="50"
            viewBox="0 0 44 44"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#0CB7EF"
          >
            <g fill="none" fillRule="evenodd" strokeWidth="2">
              <circle cx="22" cy="22" r="19.9973">
                <animate
                  attributeName="r"
                  begin="0s"
                  dur="1.8s"
                  values="1; 20"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.165, 0.84, 0.44, 1"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="stroke-opacity"
                  begin="0s"
                  dur="1.8s"
                  values="1; 0"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.3, 0.61, 0.355, 1"
                  repeatCount="indefinite"
                />
              </circle>
              <circle cx="22" cy="22" r="18.1039">
                <animate
                  attributeName="r"
                  begin="-0.9s"
                  dur="1.8s"
                  values="1; 20"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.165, 0.84, 0.44, 1"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="stroke-opacity"
                  begin="-0.9s"
                  dur="1.8s"
                  values="1; 0"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.3, 0.61, 0.355, 1"
                  repeatCount="indefinite"
                />
              </circle>
            </g>
          </svg>
        </div>
        {text && (
          <div className="row text-center justify-content-center mt-4">
            <span className="font-size-16">{text}</span>
          </div>
        )}

      </div>
    </div>

  );
}

export default LoadingComponent;
