import { Controller, useForm } from 'react-hook-form';
import {
  Button, Form, FormFeedback, FormGroup, FormProps, Input, Label,
} from 'reactstrap';
import EmailControllerComponent from '../../../../reusables/EmailController/emailControllerComponent';
import ErrorBag from '../../../../reusables/ErrorBag/ErrorBagComponent';
import { IConfirmSignUpComponentProps } from './interfaces/ConfirmSignUpComponentInterfaces';

import './scss/ConfirmSignUpComponent.scss';

function ConfirmSignUpComponent({
  onSubmit, showErrorBag, errorMessage, code = '', email = '',
}: IConfirmSignUpComponentProps): JSX.Element {
  const {
    handleSubmit, control, formState: { errors }, register,
  } = useForm({ mode: 'onBlur', reValidateMode: 'onChange' });

  function onSubmitForm(data: FormProps) {
    onSubmit(data);
  }

  return (
    <div className="row align-items-center">
      <div className="content-container">
        <div className="ConfirmSignUpComponent animate__animated animate__fadeIn">
          <div className="container box-padding">
            <div className="row text-center mb-4">
              <h3>Confirma tu cuenta</h3>
            </div>
            <div className="text-center mb-4">
              {code === '' && email === '' ? (
                <>
                  <span>
                    <b>Ingresa el código de verificación</b>
                    {' '}
                    para completar el proceso de creación de cuenta.
                  </span>
                </>
              )
                : (<></>)}
            </div>

            <ErrorBag text={errorMessage} showError={showErrorBag} />

            <Form onSubmit={handleSubmit(onSubmitForm)}>
              {code === '' ? (
                <>
                  <Controller
                    name="code"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Campo requerido',
                      maxLength: {
                        value: 128,
                        message: 'Máximo 128 caracteres',
                      },
                      pattern: {
                        value: /^[a-zA-Z0-9]+$/,
                        message: 'Formato no válido',
                      },
                    }}
                    render={({ field }) => (
                      <FormGroup>
                        <Label for="code">Código de verificación</Label>
                        <Input data-testid="code-input" type="text" {...field} invalid={typeof (errors.root) !== 'undefined'} />
                        <FormFeedback hidden={!errors.root?.message}>{errors.root?.message}</FormFeedback>
                      </FormGroup>
                    )}
                  />
                </>
              )
                : (
                  <>
                    <input {...register('code')} data-testid="code-input" type="hidden" value={code} />
                  </>
                )}

              {email === '' ? (
                <>
                  <EmailControllerComponent control={control} errors={errors} />
                </>
              )
                : (
                  <>
                    <input {...register('email')} data-testid="email-input" type="hidden" value={email} />
                  </>
                )}

              {code === '' && email === '' && (
                <>
                  <div className="text-center justify-content-center">
                    <Button disabled={Object.keys(errors).length >= 1} color="primary" className="form-button my-4">
                      <span>Confirmar</span>
                    </Button>
                  </div>
                </>
              )}
            </Form>
            <div className="text-center">
              <span>
                <a href="/"><b>volver</b></a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmSignUpComponent;
