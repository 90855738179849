import React from 'react';
import LoginContainer from '../../containers/Login/LoginContainer';
import { IMailVerificationComponentProps } from '../../containers/MailVerification/components/MailVerification/interfaces/MailVerificationComponentInterfaces';

function LoginPageRoute({ type }: IMailVerificationComponentProps) : JSX.Element {
  return (
    <div className="">
      <LoginContainer type={type} />
    </div>
  );
}

export default LoginPageRoute;
