import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormProps } from 'reactstrap';
import AppService from '../../../store/AppService';
import { StorageInterface } from '../../../store/StorageInterface';
import LoadingComponent from '../../reusables/Loading/LoadingComponent';
import MessageComponent from '../../reusables/Message/MessageComponent';
import { IMailVerificationComponentProps } from '../MailVerification/components/MailVerification/interfaces/MailVerificationComponentInterfaces';
import MailVerificationComponent from '../MailVerification/components/MailVerification/MailVerificationComponent';
import EmailSendComponent from './components/EmailSend/EmailSendComponent';
import PasswordChangeComponent from './components/PasswordChangeComponent/PasswordChangeComponent';
import PasswordChangeService from './store/PasswordChangeService';

function PasswordChangeContainer({ type }: IMailVerificationComponentProps) : JSX.Element {
  const [emailSended, setEmailSended] = useState(false);
  const [userEmail, setUserEmail] = useState(undefined);
  const [loadingMsg, setLoadingMsg] = useState('Cargando.');
  const [showErrorBag, setShowErrorBag] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [passwordChanged, setPasswordChanged] = useState(false);

  type TokenParams = {
    token: string;
    email: string;
  };
  const { token, email } = useParams<TokenParams>();

  const emailSendComponentProps = {
    onSubmit: async (data: FormProps) => {
      sendEmail(data);
    },
    cancelAction: () => {
      console.log('cancel action');
    },
    showErrorBag,
    errorMsg,
  };

  const passwordChangeComponentProps = {
    onSubmit: async (data: FormProps) => {
      changePassword(data);
    },
    cancelAction: () => {
      console.log('cancel action');
    },
    showErrorBag,
    errorMsg,
    code: token,
    email,
  };

  const appSlice = useSelector(
    (state: StorageInterface) => state.appSlice,
  );

  useEffect(() => {
    AppService.getToken();
  }, []);

  useEffect(() => {
    if (type === 'change') {
      setLoadingMsg('Verificando enlace.');
    }
  }, [type]);

  async function changePassword(data: FormProps) {
    setLoadingMsg('Cambiando tu contraseña.');
    const result = await PasswordChangeService.changePassword(data);
    setShowErrorBag(!result.success);
    setErrorMsg(result.error);
    setPasswordChanged(result.success);
    AppService.setLoadingApp(false);
  }

  async function sendEmail(data: FormProps) {
    setLoadingMsg('Enviando el correo.');
    setUserEmail(data.email);
    const result = await PasswordChangeService.sendEmail(data);
    setShowErrorBag(!result.success);
    setErrorMsg(result.error);
    setEmailSended(result.success);
    AppService.setLoadingApp(false);
  }

  return (
    <div>
      {appSlice?.loading ? (
        <LoadingComponent text={loadingMsg} fullscreen />
      ) : (
        <>
          {type === 'change' && !passwordChanged && (
            <PasswordChangeComponent {...passwordChangeComponentProps} />
          )}
          {type === 'change' && passwordChanged && (
            <MessageComponent type="success" text="Tu contraseña fue modificada exitosamente" button="Ir a mi cuenta" />
          )}

          {type === 'request' && !emailSended && (
            <EmailSendComponent {...emailSendComponentProps} />
          )}
          {type === 'request' && emailSended && (
            <MailVerificationComponent email={userEmail} type="password" />
          )}
        </>
      )}
    </div>
  );
}

export default PasswordChangeContainer;
