import {
  FormGroup, Label, Input, FormFeedback, InputGroupText, InputGroup,
} from 'reactstrap';
import { Controller } from 'react-hook-form';
import { CloseCircleFilled } from '@ant-design/icons';
import { EmailControllerProps } from './interfaces/emailControllerInterface';

export default function EmailControllerComponent({ control, errors }:EmailControllerProps):JSX.Element {
  return (
    <Controller
      name="email"
      control={control}
      defaultValue=""
      rules={{
        required: 'Este campo es obligatorio',
        maxLength: {
          value: 255,
          message: 'Máximo 255 caracteres',
        },
        pattern: {
          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
          message: 'El formato del correo no es válido',
        },
      }}
      render={({ field }) => (
        <FormGroup>
          <Label for="email">Email</Label>
          <InputGroup className="af-input-group">
            <Input className="default-input" data-testid="email-input" type="email" {...field} invalid={errors.email != null} placeholder="usuario@correo.cl" />
            <InputGroupText className={!errors.email ? '' : 'border-red'}>
              <CloseCircleFilled className="append-icon invalid-feedback" hidden={errors.email?.type === 'required' || !errors.email} />
            </InputGroupText>
            <FormFeedback data-testid="email-feedback" hidden={!errors.email}>{`${errors.email?.message}`}</FormFeedback>
          </InputGroup>
        </FormGroup>
      )}
    />
  );
}
