import AppConfig from '../../AppConfig';

const sendBrevoData = async (name: string, email: string, event: string, userId: string): Promise<void> => {
  const data = {
    name: name.toUpperCase(),
    email,
    body: {
      email,
      event,
      properties: {
        gtmEvent: event,
        name: name.toUpperCase(),
        idUsuario: userId,
      },
    },
  };

  const url = `${AppConfig.userSimulatorTrankingApiUrlRetail}/brevo/trackEvent`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (responseData.status === 'OK') {
      console.log('Brevo user data saved');
    } else {
      console.log('Brevo user data not saved');
    }
  } catch (err) {
    console.log('Brevo data error from server');
  }
};

export default sendBrevoData;
