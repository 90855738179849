import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AppService from '../../../store/AppService';
import MessageComponent from '../../reusables/Message/MessageComponent';
import LoginService from '../Login/store/LoginService';

function LogoutContainer() : JSX.Element {
  const search = useLocation();
  const callback = new URLSearchParams(search?.search).get('callback');
  const landing = new URLSearchParams(search?.search).get('landing');
  const referral = new URLSearchParams(search?.search).get('referral');

  useEffect(() => {
    AppService.getToken();
  }, []);

  useEffect(() => {
    const logout = LoginService.logout({ callback, landing, referral });
    logout.then((result) => {
      setTimeout(() => {
        window.location.replace(result.redirectTo);
      }, 1500);
    });
  }, []);

  return (
    <div>
      <MessageComponent
        type="loading"
        text="Cerrando sesión"
        button=""
      />
    </div>
  );
}
export default LogoutContainer;
