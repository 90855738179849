import './scss/SignUpComponent.scss';
import {
  Button, Form, FormGroup, Label, Input, FormFeedback, InputGroup, InputGroupText, FormProps,
} from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import {
  CloseCircleFilled,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ISignUpComponentProps } from './interfaces/SignUpComponentInterfaces';

import ErrorBagComponent from '../../../../reusables/ErrorBag/ErrorBagComponent';
import AppConfig from '../../../../../AppConfig';
import PasswordControllerComponent from '../../../../reusables/PasswordController/passwordControllerComponent';
import EmailControllerComponent from '../../../../reusables/EmailController/emailControllerComponent';

import { ReactComponent as GoogleAutofact } from '../../../../../resources/icons/google-rainbow-icon.svg';
import { StorageInterface } from '../../../../../store/StorageInterface';

function SignUpComponent({
  onSubmit, showErrorBag, errorMessage, socialRegister,
}: ISignUpComponentProps) : JSX.Element {
  const {
    getValues, handleSubmit, control, formState: { errors },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onChange' });

  const textValidationPattern = /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g;

  function onSubmitForm(data: FormProps) {
    onSubmit(data);
  }

  const headerSlice = useSelector(
    (state: StorageInterface) => state.headerSlice,
  );

  return headerSlice?.isRetailHost === true
    ? (
      <div
        className={`SignUpComponent retail-design my-5 animate__animated ${Object.keys(errors).length === 0 ? '' : 'animate__headShake'}`}
      >
        <div className="bg-white ">
          <div className="">
            <div className="p-relative w-100 mb-4">
              <h1 className="font-size-16">Crea una cuenta a través de Google.</h1>
            </div>
            <ErrorBagComponent text={errorMessage} showError={showErrorBag} />
          </div>
          <div className=" text-md-center">
            <div className="gx-0 mb-4 ">
              <Button
                outline
                color="primary"
                className="w-100"
                onClick={() => socialRegister && socialRegister('google')}
              >
                <div className="d-flex justify-content-center">
                  <GoogleAutofact className="form-button-icon align-self-center" />
                  <span className="align-self-center">Continuar con Google</span>
                </div>
              </Button>
            </div>
          </div>
          <div className="mb-4">
            <p className="">También puedes completar el siguiente formulario</p>
          </div>
          <Form onSubmit={handleSubmit(onSubmitForm)} className="mx-auto">
            <div className="sign-up-name-group">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Este campo es obligatorio',
                  maxLength: {
                    value: 50,
                    message: 'Máximo 50 caracteres',
                  },
                  pattern: {
                    value: textValidationPattern,
                    message: 'Solo espacios y letras permitidos',
                  },
                }}
                render={({ field }) => (
                  <FormGroup>
                    <Label for="name">Nombre</Label>
                    <InputGroup>
                      <Input
                        className="sign-up-name"
                        data-testid="name-input"
                        type="text"
                        {...field}
                        invalid={errors.name != null}
                        placeholder="Juan"
                      />
                      <InputGroupText className={!errors.name ? '' : 'border-red'}>
                        <CloseCircleFilled
                          className="append-icon invalid-feedback"
                          hidden={errors.name?.type === 'required' || !errors.name}
                        />
                      </InputGroupText>
                      <FormFeedback
                        data-testid="name-feedback"
                        hidden={!errors.name}
                      >
                        {errors.name?.message}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                )}
              />
              <Controller
                name="family_name"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Este campo es obligatorio',
                  maxLength: {
                    value: 50,
                    message: 'Máximo 50 caracteres',
                  },
                  pattern: {
                    value: textValidationPattern,
                    message: 'Solo espacios y letras permitidos',
                  },
                }}
                render={({ field }) => (
                  <FormGroup>
                    <Label for="family_name">Apellidos</Label>
                    <InputGroup>
                      <Input
                        data-testid="family_name-input"
                        type="text"
                        {...field}
                        invalid={errors.family_name != null}
                        placeholder="Pérez"
                      />
                      <InputGroupText className={!errors.family_name ? '' : 'border-red'}>
                        <CloseCircleFilled
                          className="append-icon invalid-feedback"
                          hidden={errors.family_name?.type === 'required' || !errors.family_name}
                        />
                      </InputGroupText>
                      <FormFeedback
                        data-testid="family_name-feedback"
                        hidden={!errors.family_name}
                      >
                        {errors.family_name?.message}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                )}
              />
            </div>

            <EmailControllerComponent
              control={control}
              errors={errors}
            />
            <PasswordControllerComponent
              control={control}
              errors={errors}
              isConfirmation={false}
              getValues={null}
            />

            <PasswordControllerComponent
              control={control}
              errors={errors}
              isConfirmation
              getValues={getValues}
            />
            {/* <Controller
              name="accept_terms"
              control={control}
              defaultValue=""
              rules={{
                required: 'Este campo es obligatorio',
              }}
              render={({ field }) => (
                <FormGroup check inline className="mt-4 mb-4">
                  <Input
                    data-testid="accept_terms-input"
                    {...field}
                    type="checkbox"
                    invalid={errors.accept_terms != null}
                  />
                  <Label className="font-lighter font-size-14" check for="accept_terms" htmlFor="accept_terms">
                    Acepto los
                    {' '}
                    <a
                      href={AppConfig.termsUrl}
                      className="text-reset font-lighter font-size-14 text-decoration"
                      target="_blank"
                      rel="noreferrer"
                    >
                      términos y condiciones
                    </a>
                    {' '}
                    y
                    {' '}
                    <a
                      href={AppConfig.privacyUrl}
                      className="text-reset font-lighter font-size-14 text-decoration"
                      target="_blank"
                      rel="noreferrer"
                    >
                      política de privacidad
                    </a>
                    <FormFeedback
                      data-testid="accept_terms-feedback"
                      className={errors.accept_terms && 'd-block'}
                    >
                      {errors.accept_terms?.message}
                    </FormFeedback>
                  </Label>
                </FormGroup>
              )}
            /> */}
            <Controller
              name="checkbox_email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormGroup check inline className="mt-4 mb-4">
                  <Input
                    data-testid="checkbox_email-input"
                    {...field}
                    type="checkbox"
                  />
                  <Label className="font-lighter font-size-14" check for="checkbox_email" htmlFor="checkbox_email">
                    Acepto recibir comunicaciones comerciales de Autofact
                  </Label>
                </FormGroup>
              )}
            />
            <Controller
              name="checkbox_communication"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormGroup check inline className="mt-4 mb-4">
                  <Input
                    data-testid="checkbox_communication-input"
                    {...field}
                    type="checkbox"
                  />
                  <Label className="font-lighter font-size-14" check for="checkbox_communication" htmlFor="checkbox_communication">
                    Acepto recibir e-mails como medio de comunicación
                  </Label>
                </FormGroup>
              )}
            />

            <div className="text-center justify-content-center">
              <Button
                data-testid="signup-button"
                disabled={Object.keys(errors).length >= 1}
                color="primary"
                className="form-button"
              >
                <span>Registrarme</span>
              </Button>
            </div>
            <div className="mt-3">
              Al registrarme o realizar log-in, aceptas los
              {' '}
              <a
                href={AppConfig.termsUrl}
                className="text-reset font-lighter font-size-14 text-decoration"
                target="_blank"
                rel="noreferrer"
              >
                términos y condiciones
              </a>
              {' '}
              y
              {' '}
              <a
                href={AppConfig.privacyUrl}
                className="text-reset font-lighter font-size-14 text-decoration"
                target="_blank"
                rel="noreferrer"
              >
                política de privacidad
              </a>
            </div>

          </Form>
          {/* <div className="mt-3 text-center">
            <a className="text-blue" href="/">Cancelar y volver</a>
          </div> */}
        </div>
      </div>
    )
    : (
      <div
        className={`SignUpComponent default animate__animated ${Object.keys(errors).length === 0 ? '' : 'animate__headShake'}`}
      >
        <div className="container box-padding ">
          <div className="row">
            <div className="p-relative text-center w-100 mb-4" style={{ zIndex: 99 }}>
              <h3 className="text-blue">Regístrate para crear una cuenta</h3>
            </div>
            <ErrorBagComponent text={errorMessage} showError={showErrorBag} />
          </div>
          <div className="text-center mb-4">
            <p className="font-size-16">completa el siguiente formulario para finalizar tu registro.</p>
          </div>
          <Form onSubmit={handleSubmit(onSubmitForm)} className="mx-auto">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: 'Este campo es obligatorio',
                maxLength: {
                  value: 50,
                  message: 'Máximo 50 caracteres',
                },
                pattern: {
                  value: textValidationPattern,
                  message: 'Solo espacios y letras permitidos',
                },
              }}
              render={({ field }) => (
                <FormGroup>
                  <Label for="name">Nombre</Label>
                  <InputGroup>
                    <Input data-testid="name-input" type="text" {...field} invalid={errors.name != null} />
                    <InputGroupText className={!errors.name ? '' : 'border-red'}>
                      <CloseCircleFilled
                        className="append-icon invalid-feedback"
                        hidden={errors.name?.type === 'required' || !errors.name}
                      />
                    </InputGroupText>
                    <FormFeedback
                      data-testid="name-feedback"
                      hidden={!errors.name}
                    >
                      {errors.name?.message}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              )}
            />
            <Controller
              name="family_name"
              control={control}
              defaultValue=""
              rules={{
                required: 'Este campo es obligatorio',
                maxLength: {
                  value: 50,
                  message: 'Máximo 50 caracteres',
                },
                pattern: {
                  value: textValidationPattern,
                  message: 'Solo espacios y letras permitidos',
                },
              }}
              render={({ field }) => (
                <FormGroup>
                  <Label for="family_name">Apellidos</Label>
                  <InputGroup>
                    <Input
                      data-testid="family_name-input"
                      type="text"
                      {...field}
                      invalid={errors.family_name != null}
                    />
                    <InputGroupText className={!errors.family_name ? '' : 'border-red'}>
                      <CloseCircleFilled
                        className="append-icon invalid-feedback"
                        hidden={errors.family_name?.type === 'required' || !errors.family_name}
                      />
                    </InputGroupText>
                    <FormFeedback
                      data-testid="family_name-feedback"
                      hidden={!errors.family_name}
                    >
                      {errors.family_name?.message}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              )}
            />

            <EmailControllerComponent
              control={control}
              errors={errors}
            />
            <PasswordControllerComponent
              control={control}
              errors={errors}
              isConfirmation={false}
              getValues={null}
            />

            <PasswordControllerComponent
              control={control}
              errors={errors}
              isConfirmation
              getValues={getValues}
            />
            <Controller
              name="accept_terms"
              control={control}
              defaultValue=""
              rules={{
                required: 'Este campo es obligatorio',
              }}
              render={({ field }) => (
                <FormGroup check inline className="mt-4 mb-4">
                  <Input
                    data-testid="accept_terms-input"
                    {...field}
                    type="checkbox"
                    invalid={errors.accept_terms != null}
                  />
                  <Label check for="accept_terms" htmlFor="accept_terms">
                    Acepto los
                    {' '}
                    <a href={AppConfig.termsUrl} className="text-reset" target="_blank" rel="noreferrer">
                      términos y
                      condiciones
                    </a>
                    {' '}
                    y
                    {' '}
                    <a href={AppConfig.privacyUrl} className="text-reset" target="_blank" rel="noreferrer">
                      política
                      de privacidad
                    </a>
                    <FormFeedback
                      data-testid="accept_terms-feedback"
                      className={errors.accept_terms && 'd-block'}
                    >
                      {errors.accept_terms?.message}
                    </FormFeedback>
                  </Label>
                </FormGroup>
              )}
            />

            <div className="text-center justify-content-center">
              <Button
                data-testid="signup-button"
                disabled={Object.keys(errors).length >= 1}
                color="primary"
                className="form-button"
              >
                <span>Crear cuenta</span>
              </Button>
            </div>

          </Form>
          <div className="mt-3 text-center">
            <span className="font-size-14">
              ¿Ya tienes una cuenta?
              {' '}
              <a href="/">Ingresa aquí</a>
            </span>
          </div>
        </div>
      </div>
    );
}

export default SignUpComponent;
