import parse from 'html-react-parser';

import './scss/MailVerificationComponent.scss';
import { CheckCircleFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { IMailVerificationComponentProps } from './interfaces/MailVerificationComponentInterfaces';

function MailVerificationComponent(props: IMailVerificationComponentProps) : JSX.Element {
  interface IContentsProps {
    title?: string;
    content: string | '';
    email?: string;
    button?: string;
    buttonUrl?: string;
    ask?: string;
  }
  const [content, setContent] = useState<IContentsProps>();

  const { type, email, message } = props;

  useEffect(() => {
    if (type === 'account') {
      setContent({
        title: 'Revisa tu bandeja de entrada',
        content: (message !== undefined) ? message : '<span>Te hemos enviado un correo con las instrucciones para recuperar tu cuenta Autofact.</span>',
        email: (email || ''),
        button: 'Aceptar',
        buttonUrl: '/',
        ask: '¿No te llegó el correo?',
      });
    }

    if (type === 'password') {
      setContent({
        title: 'Revisa tu bandeja de entrada',
        content: (message !== undefined) ? message : '<span>Te hemos enviado un correo con las instrucciones para recuperar tu cuenta Autofact.</span>',
        email: (email || ''),
        button: 'Aceptar',
        buttonUrl: '/',
        ask: '¿No te llegó el correo?',
      });
    }
  }, [props, type]);

  return (
    <Modal isOpen className="row modal-mail-verification-component modal-dialog-centered">
      <div className="animate__animated animate__fadeIn">
        <div className="MailVerificationComponent">
          <div className="text-center mb-1">
            <CheckCircleFilled className="text-green green-check-icon" />
          </div>
          <div className="text-center mb-4">
            <h3>{content?.title}</h3>
          </div>
          <div className="mx-auto text-center">
            {content?.content && (
              <p className="font-size-16 pb-4 px-0">{parse(content?.content)}</p>
            )}
            {/* En caso de necesitas email y otros contenidos visitar PUBLICO-235 */}
          </div>
          <Button outline href={content?.buttonUrl} className="w-100 bg-blue text-white border-radius-12-px">{content?.button}</Button>
        </div>
      </div>
    </Modal>
  );
}

export default MailVerificationComponent;
