import WebFont from 'webfontloader';

export default function loadWebfonts(): void {
  setTimeout(() => {
    WebFont.load({
      google: {
        families: ['Source Sans Pro:400,600,700', 'Raleway:400,700', 'Roboto:400,700'],
      },
    });
  }, 0);
}
