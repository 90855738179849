import './scss/LoginComponent.scss';
import {
  Button, Form,
} from 'reactstrap';
import { UserOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { ReactComponent as GoogleAutofact } from '../../../../../resources/icons/google-rainbow-icon.svg';

import ErrorBag from '../../../../reusables/ErrorBag/ErrorBagComponent';
import { ILoginComponentProps } from './interfaces/LoginComponentInterfaces';
import AppConfig from '../../../../../AppConfig';
import PasswordControllerComponent from '../../../../reusables/PasswordController/passwordControllerComponent';
import EmailControllerComponent from '../../../../reusables/EmailController/emailControllerComponent';

function LoginComponent({
  showErrorBag, socialLogin, isRetail, refreshToken, onSubmit, errorMessage, onSubmitResendConfirmationCode,
}: ILoginComponentProps): JSX.Element {
  const {
    handleSubmit, formState: { errors }, control,
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange', delayError: 1000 });
  return isRetail
    ? (
      <div className="loginComponent-container m-auto m-lg-0 retail-design">
        <div
          className={`LoginComponent animate__animated ${Object.keys(errors).length === 0 ? '' : 'animate__headShake'}`}
        >
          <div className="bg-white">
            <div className="mb-3" style={{ zIndex: 99 }}>
              <h1 className="login-title">
                Inicia sesión con tu cuenta,
                <span className="font-lighter"> si no tienes una puedes </span>
                <a className="text-decoration text-blue" href="/sign-up">registrarte aquí</a>
              </h1>
            </div>
            <ErrorBag
              text={errorMessage}
              showError={showErrorBag}
              handleSubmit={onSubmitResendConfirmationCode}
            />

            <Form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" name="refresh" value={refreshToken} />
              <input type="hidden" name="refresh_token" value={refreshToken} />
              <EmailControllerComponent
                control={control}
                errors={errors}
              />
              <PasswordControllerComponent
                control={control}
                errors={errors}
                isConfirmation={false}
                getValues={null}
              />

              <div className="mt-2 mb-4">
                <a className="text-blue font-size-14" href="/password-reset">¿Olvidaste tu contraseña?</a>
              </div>
              <div className="text-center">
                <Button disabled={Object.keys(errors).length >= 1} color="primary" className="form-button">
                  <span>Iniciar sesión</span>
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="text-center mt-2">
          <div className="mt-4 mb-3 font-bold">
            <div className="d-md-none">o</div>
            <div className="d-none d-md-block">o ingresa rápidamente con Google</div>
          </div>
          <div className="gx-0 mt-3 align-items-center">
            <Button outline color="primary" className="w-100" onClick={() => socialLogin && socialLogin('google')}>
              <div className="d-flex justify-content-center">
                <GoogleAutofact className="form-button-icon align-self-center" />
                <span className="align-self-center">Continuar con Google</span>
              </div>
            </Button>
          </div>
        </div>
      </div>
    )
    : (
      <div
        className={`LoginComponent default animate__animated ${Object.keys(errors).length === 0 ? '' : 'animate__headShake'}`}
      >
        <div className="container box-padding bg-white ">

          <div className="row text-center">
            <div className="p-relative w-100 mb-4" style={{ zIndex: 99 }}>
              <h3>Acceso clientes</h3>
            </div>
            <ErrorBag text={errorMessage} showError={showErrorBag} />
          </div>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="refresh" value={refreshToken} />
            <input type="hidden" name="refresh_token" value={refreshToken} />
            <EmailControllerComponent
              control={control}
              errors={errors}
            />
            <PasswordControllerComponent
              control={control}
              errors={errors}
              isConfirmation={false}
              getValues={null}
            />

            <div className="mt-2 mb-4">
              <a href="/password-reset">¿Olvidaste tu contraseña?</a>
            </div>
            <div className="mb-3 text-center">
              <Button disabled={Object.keys(errors).length >= 1} color="primary" className="form-button">
                <span>Ingresar</span>
              </Button>
            </div>
          </Form>
          <div className="col text-center mt-2">
            {AppConfig.Oauth2OktaActive === 'true'
                && (
                <>
                  <div className="text-center mt-4 mb-4">
                    <hr />
                  </div>
                  <div className="gx-0 mt-3">
                    <Button
                      outline
                      color="primary"
                      className="form-button form-button"
                      onClick={() => socialLogin && socialLogin('okta')}
                    >
                      <UserOutlined className="form-button-icon" />
                      Continuar con OKTA
                    </Button>
                  </div>
                </>
                )}
          </div>
        </div>
      </div>
    );
}

export default LoginComponent;
