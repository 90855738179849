import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AppConfig from '../../../AppConfig';
import { setIntendedUrl } from '../../../store/AppSlice';
import storage from '../../../store/storage';
import { StorageInterface } from '../../../store/StorageInterface';
import FooterComponent from './components/Footer/FooterComponent';
import { IFooterComponent } from './components/Footer/interfaces/FooterComponent.interfaces';
import HeaderComponent from './components/Header/HeaderComponent';
import './scss/LayoutContainer.scss';

interface ILayoutProps {
  type?: string,
}

function LayoutContainer({ type = 'header' }: ILayoutProps): JSX.Element {
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const headerSlice = useSelector(
    (state: StorageInterface) => state.headerSlice,
  );

  useEffect(() => {
    if (query.get('intendedUrl')) {
      storage.dispatch(setIntendedUrl(query.get('intendedUrl')));
    }
  }, [query]);

  // const retailHost = headers.HeaderOption(['Host']).Host;

  // TODO hace que el autofactUrl sea responsivo para todos los paises
  if (type === 'header') {
    return (
      <HeaderComponent autofactUrl={headerSlice?.isRetailHost ? AppConfig.homeAutofactUrlCl : AppConfig.allowHostBusiness} />
    );
  }
  if (type === 'footer') {
    const FooterComponentProps: IFooterComponent = {
      autofactUrl: (headerSlice?.isRetailHost ? AppConfig.homeAutofactUrlCl : AppConfig.allowHostBusiness),
      facebookUrl: AppConfig.SocialFacebookUrl,
      instagramUrl: AppConfig.SocialInstagramUrl,
      twitterUrl: AppConfig.SocialTwitterUrl,
      youtubeUrl: AppConfig.SocialYoutubeUrl,
      csrfTokenExpiration: Number(AppConfig.csrfTokenExpiration),
    };
    return (
      <FooterComponent {...FooterComponentProps} />
    );
  }
  return (<></>);
}

export default LayoutContainer;
