import React from 'react';
import SignUpContainer from '../../containers/SignUp/SignUpContainer';

function ConfirmSignUpRoute() : JSX.Element {
  return (
    <div className="container-fluid">
      <SignUpContainer type="confirm" />
    </div>
  );
}

export default ConfirmSignUpRoute;
