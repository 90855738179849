import parse from 'html-react-parser';

import './scss/NotFoundComponent.scss';
import { WarningFilled } from '@ant-design/icons';
import { Button } from 'reactstrap';
import { ReactComponent as SadCarOutlined } from '../../../resources/img/auto-triste-fondo-off.svg';

function NotFoundComponent() : JSX.Element {
  const content = {
    title: 'Página no encontrada',
    content: '<span><b>Lo sentimos, la página que buscas no existe.</b><br />Si crees que algo está roto reporta el problema a </span>',
    email: 'contacto@autofact.cl',
  };

  return (
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="content-container">
          <div className="NotFoundComponent animate__animated animate__fadeIn">
            <div className="container box-padding justify-content-center">
              <div className="row" />
              <div className="text-center mb-4">
                <SadCarOutlined className="car-outlined" />
                <WarningFilled className="text-warning font-size-34" />
              </div>
              <div className="text-center mb-4 px-3">
                <h3>{content?.title}</h3>
              </div>
              <div className="mx-auto text-center mb-4 p-3 bg-gray-soft box-message">
                <p className="font-size-16 pb-3">{parse(content?.content)}</p>
                <p className="font-size-16"><b>{content?.email}</b></p>
              </div>
              <div className="text-center">
                <Button color="primary" href="/" className="">Ir al inicio</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFoundComponent;
