import { FormProps } from 'reactstrap/es/Form';
import { v4 as uuidv4 } from 'uuid';
import AppConfig from '../../../../AppConfig';
import AppService, { authApiUrl } from '../../../../store/AppService';
import { successRegistration } from '../../../../store/AuthSlice';
import storage from '../../../../store/storage';
import headers from '../../../../resources/scripts/headers';
import pushDataLayer from '../../../../resources/scripts/pushDataLayer';
import sendBrevoData from '../../../../resources/scripts/sendBrevoData';

const RESPONSE_TYPE = 'token';

const provider = {
  google: 'Google',
  facebook: 'Facebook',
};

class SignUpService {
  routes = {
    register: '/register',
    confirmRegister: '/register/confirm',
  };

  msg = {
    success: '<b>Tu correo ha sido verificado</b> y ya puedes ingresar a tu cuenta.',
    error: '<b>Oops!</b> ha ocurrido un error, intenta nuevamente.',
  };

  async signUp(dataForm: FormProps) {
    AppService.setError(false);
    AppService.setLoadingApp(true);
    console.log('data del formulario: ', dataForm);
    try {
      const response = await fetch(`${authApiUrl}${this.routes.register}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(dataForm),
      });

      const responseData = await response.json();
      if (responseData.status === 'OK') {
        const {
          created,
          confirmed,
          resend,
          sub,
        } = responseData.data;
        if (resend) {
          AppService.setError(true);
          return { success: false, error: responseData.message };
        }
        storage.dispatch(successRegistration({ registered: created, verified: confirmed }));
        pushDataLayer('sign_up', { method: 'AF_LOGIN' });
        if (dataForm.checkbox_email === true && dataForm.checkbox_communication === true) {
          console.log('Envio de data a Brevo Regitro Exitoso');
          const userName = dataForm.name || '';
          const userId = sub;
          sendBrevoData(userName, dataForm.email, 'sign-up', userId);
        }
        return { success: true, data: created, message: responseData.message };
      }

      if (responseData.status === 'FAIL' || responseData.status === 'ERROR') {
        storage.dispatch(successRegistration({ registered: false }));
        AppService.setError(true);
      }
      return { success: false, error: responseData.message };
    } catch (e) {
      AppService.setError(true);
      return { success: false, error: this.msg.error };
    }
  }

  async confirmSignUp(dataForm: FormProps) {
    AppService.setError(false);
    AppService.setLoadingApp(true);
    try {
      const response = await fetch(`${authApiUrl}${this.routes.confirmRegister}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(dataForm),
      });

      const responseData = await response.json();
      if (responseData.status === 'OK') {
        const { created, confirmed } = responseData.data;
        storage.dispatch(successRegistration({ registered: created, verified: confirmed }));
        return { success: true, data: responseData.data, message: responseData.message };
      }

      if (responseData.status === 'FAIL' || responseData.status === 'ERROR') {
        storage.dispatch(successRegistration({ verified: false }));
        AppService.setError(true);
      }
      return { success: false, error: responseData.message };
    } catch (e) {
      AppService.setError(true);
      return { success: false, error: this.msg.error };
    }
  }

  async socialRegister(type: string) {
    AppService.setError(false);
    AppService.setLoadingApp(true);
    const identityId = uuidv4();
    sessionStorage.setItem('statetoken', identityId);
    const socialRegister = {
      google: this.googleRegister,
      facebook: this.facebookRegister,
    };
    socialRegister[type as keyof typeof socialRegister](identityId);
  }

  facebookRegister(state: string) {
    const host = headers.HeaderOption(['Host']).Host;
    const redirectUri = `https://${host}/oauth2/`;
    const url = `${AppConfig.Oauth2Serverurl}?identity_provider=${provider.facebook}&redirect_uri=${redirectUri}&response_type=${RESPONSE_TYPE}&client_id=${AppConfig.Oauth2ClientId}&state=${state}&scope=${AppConfig.Oauth2FacebookScopes}`;
    window.location.replace(url);
  }

  googleRegister(state: string) {
    const host = headers.HeaderOption(['Host']).Host;
    const redirectUri = `https://${host}/oauth2/`;
    const url = `${AppConfig.Oauth2Serverurl}?identity_provider=${provider.google}&redirect_uri=${redirectUri}&response_type=${RESPONSE_TYPE}&client_id=${AppConfig.Oauth2ClientId}&state=${state}&scope=${AppConfig.Oauth2GoogleScopes}`;
    window.location.replace(url);
  }
}

export default new SignUpService();
