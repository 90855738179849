import { useForm } from 'react-hook-form';
import {
  Button, Form, FormProps,
} from 'reactstrap';

import ErrorBag from '../../../../reusables/ErrorBag/ErrorBagComponent';
import { IEmailSendComponentProps } from './interfaces/EmailSendComponentInterfaces';
import './scss/EmailSendComponent.scss';
import EmailControllerComponent from '../../../../reusables/EmailController/emailControllerComponent';

function EmailSendComponent(props: IEmailSendComponentProps) : JSX.Element {
  const { handleSubmit, control, formState: { errors } } = useForm({ mode: 'onBlur', reValidateMode: 'onChange' });

  const { showErrorBag, errorMsg } = props;
  function onSubmit(data: FormProps) {
    const { onSubmit: submit } = props;
    submit(data);
  }
  return (
    <div className="container-af justify-content-center d-flex mt-lg-5">
      <div className="EmailSendComponent animate__animated animate__fadeIn">
        <div className="mb-4">
          <h1>Recupera tu contraseña ingresando tu email a continuación</h1>
        </div>

        <ErrorBag text={errorMsg} showError={showErrorBag} />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <EmailControllerComponent
            control={control}
            errors={errors}
          />

          <div className="text-center justify-content-center mb-4">
            <Button disabled={Object.keys(errors).length >= 1} color="primary" className="form-button mt-4">
              <span className="font-lighter">Recuperar contraseña</span>
            </Button>
          </div>
        </Form>
        <div className="text-center py-2">
          <a href="/" className="text-blue">Cancelar y volver</a>
        </div>
      </div>
    </div>
  );
}

export default EmailSendComponent;
