import {
  Button, Form, FormFeedback, FormGroup, FormProps, Input, Label,
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import EmailControllerComponent from '../../../../reusables/EmailController/emailControllerComponent';
import ErrorBag from '../../../../reusables/ErrorBag/ErrorBagComponent';
import { IPasswordChangeComponentProps } from './interfaces/PasswordChangeComponentInterfaces';

import './scss/PasswordChangeComponent.scss';
import PasswordControllerComponent from '../../../../reusables/PasswordController/passwordControllerComponent';

function PasswordChangeComponent({
  onSubmit, showErrorBag, errorMsg, code = '', email = '',
}: IPasswordChangeComponentProps) : JSX.Element {
  const {
    handleSubmit, getValues, control, formState: { errors }, register,
  } = useForm({ mode: 'onBlur', reValidateMode: 'onChange' });

  function onSubmitForm(data: FormProps) {
    onSubmit(data);
  }

  return (
    <div className="row align-items-center">
      <div className="content-container">
        <div className="PasswordChangeComponent animate__animated animate__fadeIn">
          <div className="container box-padding">
            <div className="row text-center">
              <h3>Restablecer contraseña</h3>
            </div>
            <div className="text-center my-4">
              <span>
                <b>Ingresa una nueva contraseña</b>
                {' '}
                para tu cuenta.
              </span>
            </div>

            <ErrorBag text={errorMsg} showError={showErrorBag} />

            <Form onSubmit={handleSubmit(onSubmitForm)}>
              { code === '' ? (
                <>
                  <Controller
                    name="code"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Este campo es obligatorio',
                      maxLength: {
                        value: 128,
                        message: 'Máximo 128 caracteres',
                      },
                      pattern: {
                        value: /^[a-zA-Z0-9]+$/,
                        message: 'Formato no válido',
                      },
                    }}
                    render={({ field }) => (
                      <FormGroup>
                        <Label for="code">Código de verificación</Label>
                        <Input data-testid="code-input" type="text" {...field} invalid={errors.code != null} />
                        <FormFeedback hidden={!errors.code}>{errors.code?.message}</FormFeedback>
                      </FormGroup>
                    )}
                  />
                </>
              )
                : (
                  <>
                    <input {...register('code')} data-testid="code-input" type="hidden" value={code} />
                  </>
                )}

              { email === '' ? (
                <>
                  <EmailControllerComponent
                    control={control}
                    errors={errors}
                  />
                </>
              )
                : (
                  <>
                    <input {...register('email')} data-testid="email-input" type="hidden" value={email} />
                  </>
                )}

              <PasswordControllerComponent
                control={control}
                errors={errors}
                isConfirmation={false}
                getValues={null}
              />
              <PasswordControllerComponent
                control={control}
                errors={errors}
                isConfirmation
                getValues={getValues}
              />
              <div className="text-center justify-content-center">
                <Button disabled={Object.keys(errors).length >= 1} color="primary" className="form-button mt-4">
                  <span>Actualizar contraseña</span>
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordChangeComponent;
