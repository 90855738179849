import React from 'react';
import PasswordChangeContainer from '../../containers/PasswordChange/PasswordChangeContainer';

function PasswordChangeRoute() : JSX.Element {
  return (
    <div className="container-fluid">
      <PasswordChangeContainer type="change" />
    </div>
  );
}

export default PasswordChangeRoute;
