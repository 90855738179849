import { createSlice, Slice as SliceInterface } from '@reduxjs/toolkit';
import AppConfig from '../AppConfig';

const HeaderSlice: SliceInterface = createSlice({
  name: 'HEADER',
  initialState: {
    initialHost: '',
    isRetailHost: true,
  },
  reducers: {
    setInitialHost(_, action) {
      return { ...action.payload };
    },
    setIsRetail(state) {
      return { ...state, ...verifyHostIsRetail(state.initialHost) };
    },
  },
});

function verifyHostIsRetail(host: string) {
  const hostRetailAllowed = AppConfig.allowHostRetail;
  if (host == null) return {};
  return {
    isRetailHost: hostRetailAllowed.includes(host),
  };
}

export const {
  setInitialHost,
  setIsRetail,
} = HeaderSlice.actions;

export default HeaderSlice;
