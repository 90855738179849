import React from 'react';
import parse from 'html-react-parser';
import './scss/ErrorBagComponent.scss';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { IErrorBagComponent } from './interfaces/ErrorBagComponentInterfaces';

export default function ErrorBagComponent({
  showError = false, text = '', handleSubmit,
}: IErrorBagComponent) : JSX.Element {
  return (
    <div
      data-testid="error-bag-component"
      className="error-bag-wrapper mb-4"
      style={!showError ? { pointerEvents: 'none', display: 'none' }
        : { pointerEvents: 'all', display: 'block' }}
    >
      <div className="error-bag w-100">
        <div className="row mx-auto">
          <div className="col-auto justify-content-center align-self-center">
            <ExclamationCircleFilled />
          </div>
          { text.includes('reenviar')
            ? (
              <div className="col ps-0 justify-content-center align-self-center">
                Por seguridad verifica tu e-mail. Revisa tu bandeja de entrada o spam y sigue los pasos.
                Haz
                <button
                  type="button"
                  className="text-decoration text-blue"
                  style={{ background: 'none', border: 'none' }}
                  onClick={handleSubmit}
                >
                  clic aquí
                </button>
                para reenviar la verificación.
              </div>
            )
            : <div className="col ps-0 justify-content-center align-self-center">{parse(text)}</div>}
        </div>
      </div>
    </div>
  );
}
