import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from './AuthSlice';
import AppSlice from './AppSlice';
import HeaderSlice from './HeaderSlice';

const combinedReducers = combineReducers({
  authSlice: AuthSlice.reducer,
  appSlice: AppSlice.reducer,
  headerSlice: HeaderSlice.reducer,
});

const storage = configureStore({
  reducer: combinedReducers,
});

export default storage;
