import { createSlice, Slice as SliceInterface } from '@reduxjs/toolkit';
import AppConfig from '../AppConfig';

const AppSlice: SliceInterface = createSlice({
  name: 'APP',
  initialState: {
    loading: false,
    intendedUrl: AppConfig.defaultHost,
    // originUrl: 'https://autofact.cl',
  },
  reducers: {
    appLoading(state, action) {
      return { ...state, loading: action.payload };
    },
    setIntendedUrl(state, action) {
      return { ...state, intendedUrl: action.payload };
    },
    // setOriginUrl(state, action) {
    //   return {...state, originUrl: action.payload}
    // }
  },
});

export const {
  appLoading,
  setIntendedUrl,
  setOriginUrl,
} = AppSlice.actions;

export default AppSlice;
