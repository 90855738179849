import AppConfig from '../AppConfig';
import headers from '../resources/scripts/headers';
import { appLoading } from './AppSlice';
import { setError } from './AuthSlice';
import storage from './storage';

class AppService {
  routes = {
    init: '/init',
  };

  setLoadingApp(state: boolean) {
    storage.dispatch(appLoading(state));
  }

  setError(state: boolean) {
    storage.dispatch(setError(state));
  }

  goBack() {
    window.history.back();
  }

  getAuthenticationApiHost() {
    const hostRetailAllowed = AppConfig.allowHostRetail;
    const host = (headers.HeaderOption(['Host'])).Host;
    return hostRetailAllowed.includes(host) ? AppConfig.authApiUrlRetail : AppConfig.authApiUrlBusiness;
  }

  async getToken() {
    try {
      const response = await fetch(`${authApiUrl}${this.routes.init}`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        credentials: 'include',
      });

      const responseData = await response.json();
      if (responseData.status === 'OK') {
        return true;
      }

      return false;
    } catch (err) {
      return false;
    }
  }
}

export default new AppService();

export const authApiUrl: string = new AppService().getAuthenticationApiHost();
