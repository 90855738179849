import { useSelector } from 'react-redux';
import { StorageInterface } from '../../../../../store/StorageInterface';
import { IHeaderComponent } from './interfaces/HeaderComponent.interfaces';
import './scss/HeaderComponent.scss';
import { ReactComponent as LogoAutofact } from '../../../../../resources/img/logo-autofact-version-primaria.svg';
import { ReactComponent as LogoAutofactSecondary } from '../../../../../resources/img/logo-autofact-version-secundaria.svg';

export default function HeaderComponent({ autofactUrl }: IHeaderComponent): JSX.Element {
  const redirectUrl = `https://${autofactUrl}`;
  const headerSlice = useSelector(
    (state: StorageInterface) => state.headerSlice,
  );
  return (
    <div className={`p-1 HeaderComponent ${headerSlice?.isRetailHost && 'retail-design'}`}>
      <div className="container-af">
        <a href={redirectUrl} id="logo" data-testid="logo">
          {
            headerSlice?.isRetailHost
              ? <LogoAutofact className="img-fluid" />
              : <LogoAutofactSecondary className="img-fluid" />
          }
        </a>
      </div>
    </div>
  );
}
