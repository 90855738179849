import { FormProps } from 'reactstrap';
import AppService, { authApiUrl } from '../../../../store/AppService';

class PasswordChangeService {
  routes = {
    sendEmail: '/forgot-password',
    changePassword: '/forgot-password/confirm',
  };

  msg = {
    error: '<b>Oops!</b> ha ocurrido un error, intenta nuevamente.',
    duplicate: 'Este correo ya esta siendo utilizado por otra cuenta. <a href="/"><b>Puedes ingresar aquí.</b></a>',
  };

  async changePassword(body: FormProps) {
    AppService.setError(false);
    AppService.setLoadingApp(true);

    try {
      const response = await fetch(`${authApiUrl}${this.routes.changePassword}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      });

      const responseData = await response.json();
      if (responseData.status === 'OK') {
        return { success: true };
      }
      if (responseData.status === 'FAIL' || responseData.status === 'ERROR') {
        AppService.setError(true);
      }
      return { success: false, error: responseData.message };
    } catch (e) {
      return { success: false, error: this.msg.error };
    }
  }

  async sendEmail(body: FormProps) {
    AppService.setError(false);
    AppService.setLoadingApp(true);

    try {
      const response = await fetch(`${authApiUrl}${this.routes.sendEmail}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
      });

      const responseData = await response.json();
      if (responseData.status === 'OK') {
        return { success: true };
      }
      if (responseData.status === 'FAIL' || responseData.status === 'ERROR') {
        AppService.setError(true);
      }
      return { success: false, error: responseData.message };
    } catch (e) {
      return { success: false, error: this.msg.error };
    }
  }
}

export default new PasswordChangeService();
