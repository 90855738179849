import React from 'react';
import './scss/LineDecorator.scss';
import { ReactComponent as Decorator } from '../../../resources/img/decorator.svg';
import { ReactComponent as DecoratorTwo } from '../../../resources/img/decorator-two.svg';

const LineDecorator = () :JSX.Element => (
  <div className="decorator-container">
    <Decorator className="decorator first desktop" />
    <DecoratorTwo className="decorator second desktop" />
  </div>
);

export default LineDecorator;
