import AppConfig from '../../AppConfig';

type headerType = {
  [key: string]: string
}

function verifyAllowedHost():boolean {
  const allowedHosts = AppConfig.allowHost;
  const currentHeaders = getHeaders(['Host', 'Origin']);

  if (currentHeaders == null || currentHeaders.Host == null) {
    return false;
  }

  if (currentHeaders.Origin == null) {
    return allowedHosts.includes(currentHeaders.Host);
  }
  const index = allowedHosts.findIndex((allowedHost) => currentHeaders.Origin.includes(allowedHost));
  return index !== -1 && allowedHosts[index] === currentHeaders.Host;
}

function getHeaders(requestedHeaders: string[] = ['Host']):headerType {
  const response : headerType = {};
  if (AppConfig.environment === 'development') {
    response.Host = AppConfig.defaultHost;
    return response;
  }
  if (requestedHeaders.includes('Host')) {
    response.Host = window.location.hostname;
  }
  if (requestedHeaders.includes('Origin')) {
    response.Origin = window.location.origin;
  }
  return response;
}

const headers = {
  AllowedHost: verifyAllowedHost,
  HeaderOption: getHeaders,
};

export default headers;
