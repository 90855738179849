import './scss/MessageComponent.scss';
import parse from 'html-react-parser';
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { Button } from 'reactstrap';
import { ReactComponent as HappyCarOutlined } from '../../../resources/img/auto-feliz-fondo-off.svg';
import { ReactComponent as SadCarOutlined } from '../../../resources/img/auto-triste-fondo-off.svg';
import { ReactComponent as CelebrationOutlined } from '../../../resources/img/celebration.svg';
import LoadingComponent from '../Loading/LoadingComponent';
import { IMessageComponentProps } from './interfaces/MessageComponentInterfaces';

function MessageComponent({
  button = '', text = '', subtext = '', type, spinner = false, buttonUrl = '/',
}: IMessageComponentProps) : JSX.Element {
  return (
    <div>
      {type !== 'loading' && (
        <div className="row align-items-center">
          <div className="content-container">
            <div className="MessageComponent animate__animated animate__fadeIn animate__faster">
              <div className="container box-padding">
                <div className="text-center mb-4">
                  {(type === 'success') && (
                    <>
                      <HappyCarOutlined className="car-outlined" />
                      <CheckCircleFilled className="text-green font-size-34" />
                    </>
                  )}
                  {(type === 'danger' || type === 'error' || type === 'fail') && (
                    <>
                      <SadCarOutlined className="car-outlined" />
                      <WarningFilled className="text-yellow font-size-34" />
                    </>
                  )}
                  {(type === 'welcome') && (
                    <>
                      <CelebrationOutlined className="car-outlined" />
                      <HappyCarOutlined className="car-outlined" />
                    </>
                  )}
                </div>
                { (text !== '') && (
                  <div className="row mx-auto text-center mb-4 px-3">
                    <h3 className="text-break p-0">
                      {parse(text)}
                    </h3>
                  </div>
                )}
                { (subtext !== '') && (
                  <div className="row mx-auto text-center mb-4 p-3 bg-gray-soft box-message">
                    <span className="font-size-16 p-0">
                      {parse(subtext)}
                    </span>
                  </div>
                )}
                { (spinner) && (
                  <div className="text-center mb-4">
                    <div className="spinner spinner-border text-primary" role="status" />
                  </div>
                )}

                <div className="text-center">
                  {button !== '' && (
                    <Button color="primary" href={buttonUrl} className="">{button}</Button>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      )}

      {type === 'loading' && (
        <LoadingComponent fullscreen text={text} />
      )}
    </div>

  );
}

export default MessageComponent;
