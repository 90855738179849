import {
  Input, FormFeedback, InputGroup, InputGroupText, FormGroup, Label,
} from 'reactstrap';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Controller } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { PasswordControllerProps } from './interfaces/passwordControllerInterfaces';

export default function PasswordControllerComponent({
  errors, control, isConfirmation, getValues,
}: PasswordControllerProps) :JSX.Element {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [setShowPassword]);

  const hideShowPassword = useCallback(() => {
    setShowPassword(false);
  }, [setShowPassword]);
  if (!isConfirmation) {
    return (
      <Controller
        name="password"
        control={control}
        defaultValue=""
        rules={{
          required: 'Este campo es obligatorio',
          minLength: {
            value: 6,
            message: 'Mínimo 6 caracteres',
          },
        }}
        render={({ field }) => (
          <FormGroup>
            <Label for="password">Contraseña</Label>
            <InputGroup className="af-input-group">
              <Input className="default-input" data-testid="password-input" type={showPassword ? 'text' : 'password'} {...field} invalid={errors.password != null} placeholder="contraseña" />
              <InputGroupText onMouseLeave={hideShowPassword} onMouseDown={toggleShowPassword} onMouseUp={toggleShowPassword} className={errors.password && 'border-red'}>
                {showPassword ? (
                  <EyeOutlined className="append-icon cursor-pointer" />
                ) : (
                  <EyeInvisibleOutlined className="append-icon cursor-pointer" />
                )}

              </InputGroupText>
              <FormFeedback data-testid="password-feedback" hidden={!errors.password}>{errors.password?.message}</FormFeedback>
            </InputGroup>
          </FormGroup>
        )}
      />
    );
  }
  if (isConfirmation) {
    return (
      <Controller
        name="confirmPassword"
        control={control}
        defaultValue=""
        rules={{
          required: 'Este campo es obligatorio',
          validate: (value) => value === (getValues !== null ? getValues('password') : '') || 'Las contraseñas deben coincidir',
        }}
        render={({ field }) => (
          <FormGroup>
            <Label for="confirmPassword">Confirma tu contraseña</Label>
            <InputGroup>
              <Input data-testid="confirmpassword-input" type={showPassword ? 'text' : 'password'} {...field} invalid={errors.confirmPassword != null} placeholder="contraseña" />
              <InputGroupText
                onMouseLeave={hideShowPassword}
                onMouseDown={toggleShowPassword}
                onMouseUp={toggleShowPassword}
                className={errors.confirmPassword && 'border-red'}
              >
                {showPassword ? (
                  <EyeOutlined className="append-icon cursor-pointer" />
                ) : (
                  <EyeInvisibleOutlined className="append-icon cursor-pointer" />
                )}
              </InputGroupText>
              <FormFeedback data-testid="confirmpassword-feedback" hidden={!errors.confirmPassword}>{errors.confirmPassword?.message}</FormFeedback>
            </InputGroup>
          </FormGroup>
        )}
      />
    );
  }
  return (<></>);
}
