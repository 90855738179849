import React, { useEffect } from 'react';
import './resources/scss/base/common.scss';

import { Provider } from 'react-redux';

import { Switch } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import { setInitialHost, setIsRetail } from './store/HeaderSlice';
import loadWebfonts from './resources/scripts/loadWebfonts';
import headers from './resources/scripts/headers';
import storage from './store/storage';

import LoginPageRoute from './components/routes/LoginPage/LoginPageRoute';
import LayoutContainer from './components/containers/Layout/LayoutContainer';
import PasswordChangeRequestRoute from './components/routes/PasswordChangeRequestPage/PasswordChangeRequestRoute';
import PasswordChangeRoute from './components/routes/PasswordChangePage/PasswordChangeRoute';
import NotFoundComponent from './components/reusables/NotFound/NotFoundComponent';
import LogoutRoute from './components/routes/LogoutPage/LogoutRoute';
import ConfirmSignUpRoute from './components/routes/ConfirmSignUpPage/ConfirmSignUpRoute';
import SignUpPageRoute from './components/routes/SignUpPage/SignUpPageRoute';
import LineDecorator from './components/reusables/Decorators/LineDecorator';

const App : React.FC = () => {
  useEffect(() => {
    loadWebfonts();
    storage.dispatch(setInitialHost({ initialHost: (headers.HeaderOption(['Host'])).Host }));
    storage.dispatch(setIsRetail({}));
  }, []);

  if (!headers.AllowedHost()) {
    return (<NotFoundComponent />);
  }

  return (
    <Provider store={storage}>
      <BrowserRouter>
        <LayoutContainer type="header" />
        <Switch>
          <Route exact path="/">
            <LoginPageRoute type="email" />
          </Route>
          <Route exact path="/oauth2">
            <LoginPageRoute type="social" />
          </Route>
          <Route exact path="/logout">
            <LogoutRoute />
          </Route>
          <Route exact path="/sign-up">
            <SignUpPageRoute />
          </Route>
          <Route exact path="/password-reset">
            <PasswordChangeRequestRoute />
          </Route>
          <Route exact path="/password-change/:token?/:email?">
            <PasswordChangeRoute />
          </Route>
          <Route exact path="/account-confirmation/:token?/:email?">
            <ConfirmSignUpRoute />
          </Route>
          <Route path="/not-found">
            <NotFoundComponent />
          </Route>
          <Route>
            <NotFoundComponent />
          </Route>
        </Switch>
        <LineDecorator />
        <LayoutContainer type="footer" />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
