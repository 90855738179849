import React from 'react';
import LogoutContainer from '../../containers/Logout/LogoutContainer';

function LogoutRoute() : JSX.Element {
  return (
    <div className="container-fluid">
      <LogoutContainer />
    </div>
  );
}

export default LogoutRoute;
