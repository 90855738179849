import { createSlice, Slice as SliceInterface } from '@reduxjs/toolkit';

const AuthSlice: SliceInterface = createSlice({
  name: 'AUTH',
  initialState: {
    loggedIn: false,
    registered: false,
    verified: false,
    error: false,
    message: '',
    refreshToken: '',
    redirectTo: '',
  },
  reducers: {
    successLogin(_, action) {
      return { ...action.payload };
    },
    errorLogin(_, action) {
      return { ...action.payload };
    },
    successRegistration(_, action) {
      return { ...action.payload };
    },
    setError(state, action) {
      return { ...state, error: action.payload };
    },
  },
});

export const {
  successLogin,
  errorLogin,
  resetError,
  successRegistration,
  setError,
} = AuthSlice.actions;

export default AuthSlice;
